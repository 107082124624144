@import '/src/styles/breakpoints';
@import '/src/styles/typography';
@import '/src/styles/theme';

.textOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .textBox {
    background-color: rgba($themeBG, 0.4);
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 80vw;
    margin: 24px;
    left: 0;
    top: 20%;
    gap: 8px;
    padding: 24px 32px;
    text-shadow: 0px 3px 4px rgba($themeBG, 0.4);
    backdrop-filter: blur(4px);
    &.visible {
      transition: all 0.4s ease-in-out;
      transition-delay: 0.2s;
      opacity: 1;
    }
    &.notvisible {
      opacity: 0;
    }
    strong {
      color: $themeOrange;
    }
    .bubble {
      background-color: $themeBG;
      border-radius: 20px 20px 20px 0px;
      padding: 8px 16px;
      color: $themeCyan;
    }
  }
}

@include sm {
  .textOverlay {
    .textBox {
      left: 10%;
      top: 20%;
      max-width: 550px;
    }
  }
}