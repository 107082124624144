@import '/src/styles/breakpoints';
@import '/src/styles/typography';
@import '/src/styles/theme';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin: 48px 0;
}

.item {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 348px;
  margin: 24px;
  box-shadow: 0 12px 40px rgb(0 0 0 / 40%);
  border-radius: 16px;
  &.visible {
    img {
      opacity: 1;
      top: -50px;
      margin-bottom: -40%;
      transition: all 0.6s;
      transition-delay: 0.2s;
      transform: translateY(0px);
    }
  }
  &:hover {
    img {
      transition-delay: 0.05s;
      top: -75px;
    }
  }
  img {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 50px;
    text-align: center;
    margin: auto;
    opacity: 0;
    width: 80%;
    z-index: 1;
  }
  .textBlock {
    display: flex;
    flex-direction: column;
    background-color: $themeGrayBlue350;
    height: 280px;
    border-radius: 16px;
    padding: 16px;
    .imgPadding {
      height: 35%;
    }
    .title {
      padding: 18px 0;
      border-bottom: 1px solid $themeGrayBlue700;
    }
    .desc {
      @extend .body;
      margin-top: 12px;
      color: $themeGrayBlue700;
    }
  }
}

@include sm {
  .container {
    flex-direction: row;
    margin: 120px 0 48px;
    .item {
      margin: 0 0;
      .textBlock {
        height: 300px;
        padding: 32px;
        .imgPadding {
          height: 30%;
        }
      }
    }
  }
}

@include md {
  .container {
    margin: 180px 0 120px;
    .item {
      margin: 0 0;
      .textBlock {
        height: 300px;
        padding: 32px;
        .imgPadding {
          height: 30%;
        }
      }
    }
  }
}