@import "/src/styles/breakpoints";
@import "/src/styles/typography";
@import "/src/styles/theme";

.textOverlay {
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    margin: 48px 16px;
    width: 80%;
    max-width: 1100px;
    .title {
      color: white;
      font-size: 45px;
      text-align: center;
      @extend .h1;
    }
    .description {
      @extend .subtitle2;
      text-align: center;
    }
  }
  .textBox {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 80vw;
    left: 24px;
    gap: 8px;
    text-shadow: 0px 3px 4px rgba($themeBG, 0.4);
    .bubble {
      background-color: $themeBG;
      border-radius: 20px 20px 20px 0px;
      padding: 8px 16px;
      text-transform: uppercase;
      color: $themeCyan;
    }
    button {
      @extend .button-big;
      cursor: pointer;
      margin-top: 16px;
      border-radius: 8px;
      border: none;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 48px 16px;
      width: 80%;
      max-width: 1100px;
      .description {
        @extend .subtitle1;
      }
      h1 {
        color: $themeCyan;
        text-align: center;
      }
      h3 {
        text-align: center;
      }
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

@include sm {
  .textOverlay {
    .textBox {
      left: 10%;
      max-width: 50%;
      button {
        @extend .button-big;
        margin-top: 16px;
        border-radius: 8px;
        border: none;
      }
    }
  }
}

@include md {
  .textOverlay {
    .textBox {
      max-width: 550px;
    }
  }
}
