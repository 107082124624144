@import '/src/styles/breakpoints';
@import '/src/styles/typography';
@import '/src/styles/theme';

.container {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 1.5rem;
  background-color: rgba($color: #000000, $alpha: 0.8);
  padding: 1.5rem;
  min-width: 208px;
  max-width: 584px;
  border-radius: .75rem;
  .imageWrapper {
    position: relative;
    width: 6.5rem;
    height: 6.5rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.5rem;
    }
  }
  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 0.5rem;
    height: inherit;
    overflow: hidden;
    .title {
      @extend .subtitle1;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
              line-clamp: 1; 
      -webkit-box-orient: vertical;
    }
    .player {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 4rem;
      .playButtonWrapper {

      }
      .infoWrapper {
        margin-left: 0.5rem;
        .duration {
          @extend .subtitle1;
        }
        .date {
          @extend .body;
          color: $themeGrayBlue700;
        }
      }
    }
    .description {
      @extend .body;
      color: $themeGrayBlue700;
      height: 4rem;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

@include sm {
  .container {
    .imageWrapper {
      width: 10rem;
      height: 10rem;
    }
  }
}