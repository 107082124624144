@import "/src/styles/breakpoints";
@import "/src/styles/typography";
@import "/src/styles/theme";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  padding: 188px 0;
  background-color: #08142c;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 75%;
  width: 100%;
  .textSection {
    display: flex;
    .logoText {
      padding: 0 0.75rem 0 1rem;
    }
  }
  .teamSection {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 80%;
  }
}

.item {
  display: flex;
  gap: 1.75rem;
  flex: 49%;
  flex-direction: row;
  background-color: #292936;
  min-height: 146px;
  border-radius: 8px;
  width: 542px;
  max-width: 542px;
  max-height: 176px;
  width: 100%;
  overflow: hidden;
  .profileSection {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 176px;
    height: 176px;
    img {
      width: 176px;
      height: 176px;
    }
  }
  .textSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    span {
      @extend .body;
      color: $themeGrayBlue700;
      font-weight: 600;
    }
    a {
      display: flex;
      flex-direction: row;
      text-decoration: none;
      color: $themeCyan;
      gap: 8px;
      span {
        color: $themeCyan;
      }
    }
  }
}

@include sm {
  .container {
    .teamSection {
      width: 80%;
      flex: 1;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  .item {
    min-height: 176px;
  }
}

@include md {
  .container {
    .teamSection {
      width: 50%;
      flex: 1;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}
