.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  color: white;
  margin: auto;
  gap: 16px;
  padding: 180px 0px 128px;
  a {
    color: #00DDDD;
  }
}