@import '/src/styles/breakpoints';
@import '/src/styles/typography';
@import '/src/styles/theme';

.container {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: rgba($color: $themeBG, $alpha: 0.8);
  border-radius: 16px;
  padding: 32px;
  margin: 16px;
  .titleSection {
    h2 {
      color: $themeCyan;
      margin-bottom: 16px;
    }
    p {
      @extend .p;
      line-height: 180%;
    }
  }
  .formContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 48px;
    .row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 4px;
      .optionSelect {
        @extend .p;
        text-shadow: none;
        flex: 1;
        height: 144px;
        max-width: 232px;
        background-color: pink;
        font-weight: bold;
        color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: none;
        cursor: pointer;
        &:first-child {
          border-radius: 16px 0 0 16px;
          background-color: $themeOrange;
        }
        &:last-child {
          border-radius: 0 16px 16px 0;
          background-color: $themeCyan;
        }
      }
    }
    .emailContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      label {
        @extend .p;
        font-weight: bold;
        margin-bottom: 4px;
      }
      input {
        @extend .body;
        padding: 14px 16px;
        background-color: transparent;
        outline: none;
        color: white;
        border: 2px solid $themeGrayBlue600;
        border-radius: 8px;
        width: 80%;
        &:active, &:focus {
          border: 2px solid white;
        }
        &.error {
          border-color: #FD4466;
        }
      }
    }
    .buttonContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      button {
        @extend .p;
        text-shadow: none;
        cursor: pointer;
        font-weight: bold;
        color: black;
        border-radius: 8px;
        padding: 8px 12px;
        outline: none;
        border: 2px solid transparent;
        &:active, &:focus {
          border: 2px solid $themeCyan;
        }
      }
      .successButton {
        @extend .body;
        text-shadow: none;
        font-weight: bold;
        color: black;
        background-color: #4ECE5B;
        border-radius: 8px;
        padding: 8px 12px;
        outline: none;
        border: 2px solid transparent;
      }
      .errorButton {
        @extend .body;
        text-shadow: none;
        font-weight: bold;
        color: black;
        background-color: #FD4466;
        border-radius: 8px;
        padding: 8px 12px;
        outline: none;
        border: 2px solid transparent;
      }
    }
  }
}

@include md {
  .container {
    padding: 48px 64px;
    .formContainer {
      .emailContainer {
        input {
          width: 50%;
        }
      }
    }
  }
}