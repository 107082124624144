@import "/src/styles/breakpoints";
@import "/src/styles/typography";
@import "/src/styles/theme";

.textOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    margin: 48px 16px;
    width: 80%;
    max-width: 1100px;
    .title {
      @extend .h1;
      color: white;
      text-align: center;
    }
    .secondaryTitle {
      @extend .h1;
      color: white;
      white-space: nowrap;
    }
    .description {
      @extend .subtitle2;
      text-align: center;
    }
    .openingDetailsTypeLocation {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      .openingDetails {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
  .textBox {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 80vw;
    left: 24px;
    top: 50%;
    gap: 8px;
    text-shadow: 0px 3px 4px rgba($themeBG, 0.4);
    .bubble {
      background-color: $themeBG;
      border-radius: 20px 20px 20px 0px;
      padding: 8px 16px;
      text-transform: uppercase;
      color: $themeCyan;
    }
    button {
      @extend .button-big;
      cursor: pointer;
      margin-top: 16px;
      border-radius: 8px;
      border: none;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 48px 16px;
      width: 80%;
      max-width: 1100px;
      .description {
        @extend .subtitle1;
      }
      h1 {
        color: $themeCyan;
        text-align: center;
      }
      h3 {
        text-align: center;
      }
    }
  }

  .textBoxPricing {
    position: relative;
    display: flex;
    align-items: flex-start;
    max-width: 80vw;
    text-shadow: 0px 3px 4px rgba($themeBG, 0.4);
    top: 18%;
    left: 5%;
    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      margin-top: 0;
      .buttonsContainer {
        width: 100%;
      }
      button {
        width: 100%;
        height: 48px;
      }
      .row {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }
    .bracketImageContainer {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      .transparentImage {
        opacity: 0.3;
        height: 60px;
      }
      .originalImage {
        height: 100px;
      }
    }
  }
}

.textOverlayForCareers {
  display: flex;
  align-items: center;
  justify-content: center;
  @extend .textOverlay;
  .content {
    margin-top: 80px;
    gap: 8px;
    .title {
      @extend .h1;
    }
    .description {
      @extend .subtitle2;
    }
  }
}

.textOverlayPremium {
  position: relative;
  height: 463px;
  width: 80%;
  max-width: 1100px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding-top: 5rem;
  .textBox {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    gap: 24px;
    text-shadow: 0px 3px 4px rgba($themeBG, 0.4);
    .premiumButtonContainer {
      width: 100%;
    }
  }
  .imageBox {
    width: 80%;
    margin-left: 5%;
    .imageContainer {
      margin: auto;
      img {
        transform: scale(1.5);
      }
    }
  }
  .titlePremium {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 0;
    .titleText {
      @extend .h2;
    }
    .premiumText {
      background: linear-gradient(180deg, #fad551 0%, #ff9c41 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    .description {
      @extend .p;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

@include sm {
  .textOverlay {
    .textBox {
      left: 10%;
      top: 338px;
      max-width: 550px;
      button {
        @extend .button-big;
        margin-top: 16px;
        border-radius: 8px;
        border: none;
      }
    }
    .textBoxPricing {
      position: relative;
      display: flex;
      text-shadow: 0px 3px 4px rgba($themeBG, 0.4);
      left: 10%;
      top: 18%;
      flex-direction: row;
      align-items: flex-start;
      .content {
        align-items: flex-start;
      }
      .bracketImageContainer {
        display: flex;
        align-items: center;
        gap: 0;
        .transparentImage {
          opacity: 0.3;
        }
      }
    }
  }
  .textOverlayPremium {
    .titlePremium {
      flex-direction: row;
      gap: 16px;
    }
  }
}

@include md {
  .textOverlay {
    .textBoxPricing {
      position: relative;
      display: flex;
      text-shadow: 0px 3px 4px rgba($themeBG, 0.4);
      left: 10%;
      top: 20%;
      max-width: 565px;
      flex-direction: row;
      align-items: flex-start;
      gap: 1.5rem;
      .content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        button {
          margin-top: 1.5rem;
          width: 160px;
        }
        .row {
          display: flex;
          flex-direction: row;
          gap: 1rem;
        }
      }
      .bracketImageContainer {
        display: flex;
        align-items: center;
        gap: 0;
        .transparentImage {
          width: auto;
          height: 96px;
        }
        .originalImage {
          width: auto;
          height: 152px;
        }
      }
    }
  }
  .textOverlayForCareers {
    .content {
      margin-top: 48px;
      gap: 20px;
      .openingDetailsTypeLocation {
        flex-direction: row;
        gap: 26px;
      }
    }
  }
  .textOverlayPremium {
    flex-direction: row;
    .textBox {
      width: 50%;
      align-items: flex-start;
      text-align: left;
    }
    .imageBox {
      width: 50%;
      margin-left: 0;
      .imageContainer {
        img {
          transform: scale(1.5);
        }
      }
    }
  }
}
