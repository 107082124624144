@import '/src/styles/breakpoints';
@import '/src/styles/typography';
@import '/src/styles/theme';

// @keyframes rotation {
//   0% {
//     transform: rotateY(0deg);
//   }
//   50% {
//     transform: rotateY(45deg);
//   }
//   100% {
//     transform: rotateY(0deg);
//   }
// }

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: $themeBG;
  padding: 48px 24px;
  ul {
    list-style: none;
    list-style-position: inside;
    padding: 0;
    letter-spacing: -0.3px;
    &::before {
      @extend .h4;
      content: attr(aria-label);
      font-size: 120%;
      font-weight: bold;
      letter-spacing: -0.3px;
    }
    &:first-child {
      margin-top: 12px;
    }
    li {
      @extend .body;
      color: $themeGrayBlue600;
      margin: 6px 0;
      a {
        cursor: pointer;
        color: $themeGrayBlue600;
        text-decoration: none;
        &:hover, &:visited, &:active {
          color: inherit;
          text-decoration: none;
         }
      }
    }
  }
  .brandContainer {
    display: flex;
    flex: 1;
    max-width: 188px;
    margin: 24px 0;
    img {
      animation: rotation 6s infinite;
      animation-timing-function: ease-out;
      height: 88px;
      object-fit: cover;
    }
  }
  .productSection {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    text-align: left;
  }
  .companySection {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
  }
  .socialSection {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1;
    .socialList {
      display: flex;
      flex-direction: row;
      gap: 8px;
      margin: 8px 0;
      .socialItem {
        cursor: pointer;
        width: 28px;
        height: 28px;
        img {
          width: 28px;
          height: 28px;
          object-fit: cover;
        }
      }
    }
  }
}

@include sm {
  .container {
    flex-direction: row;
    margin: 0px 24px;
    .brandContainer {
      display: flex;
    }
  }
} 

@include md {
  .container {
    flex-direction: row;
    margin: 0px 200px;
    .brandContainer {
      display: flex;
    }
  }
} 